const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Gsap - The Amsterdam based security-first IT company', // Navigation and Site Title
  siteTitleAlt: 'Gsap', // Alternative Site title for SEO
  siteTitleShort: 'Gsap', // short_name for manifest
  siteHeadline: 'Gsap - The Amsterdam based security-first IT company', // Headline for schema.org JSONLD
  siteUrl: 'https://www.gsap.nl', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo2.png', // Used for SEO and manifest
  siteDescription: 'From complete architectural review to individual advisory we cover a broad spectrum when it comes to IT',
  author: 'Gsap', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@pierrebayraktar', // Twitter Username
  ogSiteName: 'Gsap', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-158647482-1',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
